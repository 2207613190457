import { Card as MuiCard, cardClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardStyled = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 361,
  height: '100%',

  [theme.breakpoints.down('md')]: {
    maxWidth: 361,
    width: '100%',
  },

  [`&.${cardClasses.root}`]: {
    borderRadius: 15,
    border: '1px solid #e0e0e0',
  },
}));
