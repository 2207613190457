import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/ui/Chip/Chip';

import { Subheading } from './Head.style';

const Head = ({ headline, chip, mainText }) => {
  return (
    <Stack alignItems="center" mb={6.5}>
      {chip && (
        <Chip
          size="small"
          uppercase
          sx={{ alignSelf: 'center', mb: 2.5, py: 2 }}>
          {chip}
        </Chip>
      )}
      <Typography
        component="h2"
        variant="h2Redesign"
        color="primary.dark"
        mb={2}
        align="center">
        {headline}
      </Typography>
      <Subheading variant="h5Redesign" component="h3" align="center">
        {mainText}
      </Subheading>
    </Stack>
  );
};

Head.propTypes = {
  headline: PropTypes.string.isRequired,
  chip: PropTypes.string,
  mainText: PropTypes.string.isRequired,
};

export default Head;
