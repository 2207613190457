import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';

function CarouselSlide({ children }) {
  return <SwiperSlide>{children}</SwiperSlide>;
}

CarouselSlide.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CarouselSlide.displayName = 'SwiperSlide'; // needed to work as a wrapper component for SwiperSlide

export default CarouselSlide;
