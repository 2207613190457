import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Carousel from '@/components/ui/Carousel/Carousel';
import CarouselSlide from '@/components/ui/Carousel/CarouselSlide/CarouselSlide';
import PageMargins from '@/components/ui/PageMargins/PageMargins';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import Card from './Card/Card';
import Head from './Head/Head';

const ValueProposition = ({ data }) => {
  const { headline, chip, mainText, cards } = data;
  const mobileMediaQuery = useMobileMediaQuery();

  return (
    <Box my={7}>
      <PageMargins>
        <Head headline={headline} chip={chip} mainText={mainText} />
      </PageMargins>
      {cards.length < 4 || mobileMediaQuery ? (
        <PageMargins>
          <Stack
            justifyContent="center"
            alignItems={{ xs: 'center', md: 'stretch' }}
            gap={3}
            flexDirection={{ xs: 'column', md: 'row' }}>
            {cards.map(card => (
              <Card key={card.id} card={card} sx={{ height: 'auto' }} />
            ))}
          </Stack>
        </PageMargins>
      ) : (
        <Box overflow="hidden">
          <PageMargins>
            <Carousel>
              {cards.map(card => (
                <CarouselSlide key={card.id}>
                  <Card card={card} />
                </CarouselSlide>
              ))}
            </Carousel>
          </PageMargins>
        </Box>
      )}
    </Box>
  );
};

ValueProposition.propTypes = {
  data: PropTypes.shape({
    chip: PropTypes.string,
    headline: PropTypes.string.isRequired,
    mainText: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        chip: PropTypes.string,
        headline: PropTypes.string.isRequired,
        text: PropTypes.string,
        link: PropTypes.shape({
          data: PropTypes.shape({
            attributes: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }).isRequired,
          }),
        }),
        image: PropTypes.shape({
          data: PropTypes.shape({
            attributes: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }).isRequired,
          }),
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ValueProposition;
