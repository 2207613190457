import {
  Box,
  CardActions,
  CardContent,
  CardMedia,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import ChipBase from '@/components/ui/Chip/Chip';
import useFormatMessage from '@/hooks/useFormatMessage';

import { CardStyled } from './Card.style';

const Card = ({ card, sx }) => {
  const { chip, headline, text, link, image } = card;
  const formatMessage = useFormatMessage();

  return (
    <CardStyled elevation={0} sx={sx}>
      {image?.data?.attributes?.url ? (
        <CardMedia
          sx={{ height: 200, flexShrink: 0 }}
          image={image.data.attributes.url}
        />
      ) : (
        <Box sx={{ height: 200, flexShrink: 0 }} />
      )}
      <CardContent sx={{ flex: 1 }}>
        <Stack justifyContent="center" direction="column" spacing={1}>
          {chip && (
            <ChipBase size="small" sx={{ alignSelf: 'center' }}>
              {chip}
            </ChipBase>
          )}
          <Typography
            variant="h5Redesign"
            component="h4"
            color="primary.dark"
            align="center">
            {headline}
          </Typography>
          {text && (
            <Typography variant="body1Redesign" component="p" align="center">
              {text}
            </Typography>
          )}
        </Stack>
      </CardContent>
      {link?.data && (
        <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
          <Link
            href={link.data.attributes.url}
            color="primary"
            variant="body1Redesign"
            component={NextLink}>
            {formatMessage('valueProposition_link')}
          </Link>
        </CardActions>
      )}
    </CardStyled>
  );
};

Card.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.number.isRequired,
    chip: PropTypes.string,
    headline: PropTypes.string.isRequired,
    text: PropTypes.string,
    link: PropTypes.shape({
      data: PropTypes.shape({
        attributes: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    }),
    image: PropTypes.shape({
      data: PropTypes.shape({
        attributes: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  sx: PropTypes.object,
};

export default Card;
