import PropTypes from 'prop-types';
import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
import { Swiper } from 'swiper/react';

import { Wrapper } from './Carousel.style';

const defaultOptions = {
  slidesPerView: 'auto',
  freeMode: true,
  mousewheel: { releaseOnEdges: true },
  grabCursor: true,
  pagination: {
    type: 'bullets',
    clickable: true,
  },
};

function Carousel({ children, options }) {
  return (
    <Wrapper>
      <Swiper
        {...defaultOptions}
        {...options}
        modules={[FreeMode, Mousewheel, Pagination]}>
        {children}
      </Swiper>
    </Wrapper>
  );
}

Carousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  options: PropTypes.object,
};

export default Carousel;
