import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const DOT_SIZE = 8;

export const Wrapper = styled(Box)(({ theme }) => ({
  '& .swiper': {
    overflow: 'initial',
    position: 'relative',
  },
  '& .swiper-wrapper': {
    display: 'flex',
    transition: 'transform 0.3s ease',
  },
  '& .swiper-slide': {
    flex: '0 0 auto',
    width: 'auto',
    display: 'inline-block',
    marginRight: theme.spacing(3),
  },
  '& .swiper-slide:last-child': {
    marginRight: 0,
  },
  '& .swiper-pagination': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    zIndex: 1,
    paddingBlock: theme.spacing(3),
  },
  '& .swiper-pagination-bullet': {
    margin: 0,
    padding: 0,
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE / 2,
    backgroundColor: alpha(theme.palette.common.blue, 0.3),
    transition: 'width 0.3s, background-color 0.3s',
  },
  '.swiper-pagination-bullet-active': {
    width: DOT_SIZE * 2.5,
    backgroundColor: theme.palette.common.blue,
  },
  '& .swiper-pagination-clickable > .swiper-pagination-bullet': {
    cursor: 'pointer',
  },
}));
